class ColorCardParser {
    // @tobias double json encoded articles column
    parse(articles: any): any {
        const p = JSON.parse(articles);
        if (!Array.isArray(p)) return this.parse(p);

        return p;
    }
}

const colorCardParser = new ColorCardParser();
export default colorCardParser